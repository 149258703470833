"use client";
import React, { useRef, useState, useEffect } from "react";
import OrangeSpan from "@/Ui_Elements/OrangeSpan/OrangeSpan";
import style from "@/styles/Homepage/FrequentQuestions.module.scss";
import { GoDash ,GoPlus} from "react-icons/go";

const AccordionItem = (props) => {
  const contentEl = useRef(null);
  const { handleToggle, active, faq, id } = props;
  const { que, ans } = faq;

  function createMarkup(content) {
    return { __html: content };
  }

  useEffect(() => {
    if (active === id && contentEl.current) {
      contentEl.current.style.height = `${contentEl.current.scrollHeight}px`;
    } else if (contentEl.current) {
      contentEl.current.style.height = "0px"; // Ensure it's 0px when not active
    }
  }, [active, id]);

  return (
    <div className={`${style["rc_accordion_card"]}`}>
      <div className={`${style["rc_accordion_header"]}`}>
        <div
          className={`${style["rc_accordion_toggle"]} p-3 ${active === id ? `${style["active"]}` : ""}`}
          onClick={() => handleToggle(id)}
        >
          <div>
            {" "}
            <h5 className={`${style["rc_accordion_title"]}`}>{que}</h5>
          </div>
          <div>
            {" "}
            {active === id ? <GoDash className={`${style["sized"]}`} /> : <GoPlus className={`${style["sized"]}`} />}
          </div>
        </div>
      </div>
      <div
        ref={contentEl}
        className={`${style["rc_collapse"]} ${active === id ? `${style["show"]}` : ""}`}
        style={{
          height: "0px",
          transition: "height 0.3s ease", // Smooth transition when opening/closing
          overflow: "hidden",
        }}
      >
        <div className={`${style["rc_accordion_body"]}`} dangerouslySetInnerHTML={createMarkup(ans)}></div>
      </div>
    </div>
  );
};

const FrequentQuestions = (props) => {
  const { questions, class123, sectionbgshapeclass } = props.data;

  // Initialize with the first accordion item open by default (set active to 1)
  const [active, setActive] = useState(1);

  const handleToggle = (index) => {
    if (active === index) {
      setActive(null); // Close the currently open accordion
    } else {
      setActive(index); // Open the clicked accordion
    }
  };

  return (
    <>
      <section
        className={`${style["frequent-questions"]} ${style[class123]} commnabgshape ${style[props.pageIdentity]} ${
          sectionbgshapeclass ? sectionbgshapeclass : ""
        }`}
      >
        <div className="container">
          <div className="">
            <div className={` ${style["center"]}`}>
              <OrangeSpan text={`FAQ`} classname={`${style["badge"]} tx-center`} />
              <h3 className="commonsectiontitle">Frequently Asked Questions</h3>
            </div>
          </div>
          <div className="">
            <div id={`${style["accordion"]}`} className="">
              {questions.map((faq, index) => (
                <AccordionItem key={index} id={index + 1} active={active} handleToggle={handleToggle} faq={faq} />
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FrequentQuestions;
